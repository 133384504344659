.scene {
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.scene canvas {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 0 auto;
}
