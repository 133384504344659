html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

/********************************* My Code *********************************/
body {
  background-color: black;
  color: #afd3d4;
}
.btn:hover,
a:hover {
  cursor: cell;
}
a:visited {
  color: #df541d;
}

span {
  color: #df541d;
}

.main h1 {
  font-family: "Orbitron", sans-serif;
  font-size: 80px;
  margin: 20px 0;
  color: #afd3d4;
  line-height: 80px;
}

.landing_p {
  font-family: "Libre Barcode 128 Text", system-ui;
  font-size: 40px;
  margin: 20px 0;
  color: #afd3d4;
  line-height:60px;
}

.intro_heading {
  font-family: "Libre Barcode 128 Text", system-ui;
  font-size: 60px;
  margin: 20px 0;
  color: #afd3d4
}

.work_stack {
  color: #e23b4a
}

/*---------------------- Blink -----------------------*/
.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
/*---------------------- Blink -----------------------*/

/*---------------------- Gradient -----------------------*/
.gradient1 {
  background: #000000;
  color: black;
  position: relative;
  text-shadow: 1px 1px 0px #000000, 1px -1px 0px #000000, -1px -1px 0px #000000,
    -1px 1px 0px #000000;
}
.gradient1::before,
.gradient1::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.gradient1::before {
  background: linear-gradient(
    to right,
    #27328e,
    #faaa54,
    #e23b4a,
    #db0768,
    #360670
  );
  content: "";
  display: block;
  mix-blend-mode: screen;
}
.gradient1::after {
  content: '';
  background: #000000;
  color: white;
  mix-blend-mode: multiply;
  z-index: 1;
}
/* ---------------------- Gradient ----------------------- */
h3,
p,
.field ul li {
  font-family: "Black Ops One", system-ui;
  font-size: 15px;
  line-height: 25px;
}
#particle-js {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 1;
}
/* React Transition */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms linear 500ms;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}
/* enter */
.left-enter {
  transform: translate(-120%);
}
.left-enter.left-enter-active {
  transform: translate(0%);
  transition: transform 500ms linear;
}
/* exit */
.left-exit {
  transform: translate(0%);
}
.left-exit.left-exit-active {
  transform: translate(120%);
  transition: transform 500ms linear;
}
/* enter */
.right-enter {
  transform: translate(120%);
}
.right-enter.right-enter-active {
  transform: translate(0);
  transition: transform 500ms linear;
}
/* exit */
.right-exit {
  transform: translate(0);
}
.right-exit.right-exit-active {
  transform: translate(-120%);
  transition: transform 500ms linear;
}
.item-container {
  position: relative;
  margin: 0 auto;
  top: 10%;
  z-index: 99;
}
.item {
  text-align: center;
  margin: 0 auto;
  color: #afd3d4;
  position: absolute;
  left: 0;
  right: 0;
  max-height: 60vh;
  width: 80%;
}
.section {
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;
}
.control {
  position: relative;
  display: block;
  margin: 0 auto;
  z-index: 99;
  margin-top: 80vh;
  text-align: center;
}
ul.control li {
  display: inline;
}
ul.control li a,
.btn {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  background-color: #0c3b94;
  color: #afd3d4;
  font-size: 12px;
  padding: 1rem 1rem;
  text-decoration: none;
  font-family: "Black Ops One", system-ui;
}
.about-icon {
  font-size: 40px;
  color: #6dafec;
  padding-left: 1rem;
}
h1 {
  font-size: 30px;
  line-height: 45px;
}
.main p strong {
  color: #e42626;
  text-transform: uppercase;
}
.item h3 {
  margin: 5px;
}
ul.control li .orange {
  background-color: #e42626;
}
.social {
  margin: 20px 0;
}
.info-btn {
  position: relative;
  margin-top: 220px;
  z-index: 99;
}
.info-item {
  position: relative;
  z-index: 99;
}
.field ul {
  border-right: 2px solid blue;
}
.icon-group {
  position: relative;
  background: #afd3d4;
  opacity: 0.8;
  display: inline;
  padding: 30px 10px 0px 10px;
  border-radius: 15px;
  z-index: 99;
}
.icon-group img {
  margin: 5px;
  zoom: 0.9;
}
.demo-link {
  position: absolute;
  background: #0c3b94;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.demo-link:hover {
  opacity: 0.5;
  transition: opacity 1s;
}
.wrapper {
  position: relative;
  width: auto;
  height: 300px;
  margin: 10px auto;
  z-index: 99;
}
.scaled-gif {
  margin-top: 20px;
  max-width: 100%;
  max-height: 100%;
}
.fullscreen-video {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  z-index: -99;
}
.video-overlay {
  background: #10e6db;
  opacity: 0.6;
  z-index: -99;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #949494;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #366088;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #366088;
}
@media only screen and (min-width: 769px) {
  h3,
  p
  {
    font-size: 25px;
    line-height: 40px;
  }
  h1 {
    font-size: 30px;
    line-height: 45px;
  }

  .landing_p {
    font-size: 60px;
    margin: 20px 0;
    line-height:100px;
  }
  
}
@media only screen and (min-height: 768px) {
  .info-btn {
    margin-top: 330px;
  }
}
